import mergeImages from 'merge-images';

import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { isNullOrUndefined, isNullOrWhiteSpace } from '../shared/helpers/helper-functions';

@Component({
  selector: 'app-share-click',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
  templateUrl: './share-click.component.html',
  styleUrl: './share-click.component.scss',
})
export class ShareClickComponent implements OnInit {
  public showSpinner = signal<boolean>(false);
  public buzzwaveAdminAssetsS3Url = signal<string>('');
  public progressValue = signal<number>(10);
  private awsApiUrl2 = environment.buzzwaveApiUrl;
  private buzzwaveS3Url = environment.buzzwaveClientAssetsS3Url;
  private postImageSrc = '';
  private socialPostImageHeight = 555;
  private socialPostImageWidth = 555;
  private textLineCount = 1;
  private maxTextWidth = 21;

  constructor(private router: Router) {
    this.buzzwaveAdminAssetsS3Url.set(environment.buzzwaveAdminAssetsS3Url);
    
    window.onbeforeunload = function () {
      sessionStorage.setItem("buzzwave-origin", window.location.href);
    };
  }

  async ngOnInit(): Promise<void> {
    const url = new URL(window.location.href);
    const authorization_code = url.searchParams.get('code');
    const state = url.searchParams.get('state');
    const campaignId = url.searchParams.get('buzzwavecampaignid');
    const forwardUrl = url.searchParams.get('forwardurl');
    const clientAccountId = url.searchParams.get('buzzwaveclientaccountid');
    const viewedCampaignShareId = url.searchParams.get('buzzwavecampaignshareid');
    const jobTitle = url.searchParams.get('buzzwavejobtitle') ?? '';
    const company = url.searchParams.get('buzzwavecompany') ?? '';
    const testMode = url.searchParams.get('testMode');

    if (authorization_code && state) {
      this.showSpinner.set(true);
      const getLinkedInMetadataUrl = this.awsApiUrl2 + '/linkedin-metadata';
      const createLinkedInPostUrl = this.awsApiUrl2 + '/campaign-linkedin-share';
      const headers = { 'content-type': 'application/json' };

      // Get supporting advert metadata for use in the post
      const campaignMetadataStorageValue = sessionStorage.getItem('buzzwave-campaign-metadata');
      let campaignMetadata = campaignMetadataStorageValue
        ? JSON.parse(campaignMetadataStorageValue)
        : null;

      try {
        if (!campaignMetadata) {
          this.progressValue.set(15);
          // Get the client's advert metadata from AWS
          const url =
            this.awsApiUrl2 + `/campaigns-by-clientaccountid?clientAccountId=${clientAccountId}`;
          const headers = { 'content-type': 'application/json' };

          try {
            const result = await fetch(url, {
              method: 'GET',
              headers: headers,
              mode: 'cors', cache: 'no-store', credentials: 'omit'
            });

            const results = await result.json();

            const campaignArray = Array.isArray(results) ? results : [];

            if (campaignArray.length > 0) {
              campaignMetadata = campaignArray;
              sessionStorage.setItem(
                'buzzwave-campaign-metadata',
                JSON.stringify(campaignMetadata)
              );
            }
          } catch (error) {
            console.error(error);
          }
        }

        this.progressValue.set(20);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const campaign = campaignMetadata.find((c: any) => c.campaignId === campaignId);

        document.title = campaign.eventName;

        let postImageUrl = campaign.postImageUrl;

        if (campaign.isTailorMade) {
          postImageUrl = campaign.participantPostImageUrl;
        }

        await this.getBase64ImageFromUrl(`${this.buzzwaveS3Url}/${postImageUrl}`).then(
          (base64) => {
            this.postImageSrc = base64 as string;
          }
        );
        const getLinkedInMetadataBody = {
          authorization_code: authorization_code,
          state: state,
          clientAccountId: clientAccountId,
          postText: campaign.postTitle,
          postBody: campaign.postBody,
          postForwardUrl: campaign.postForwardUrl,
          jobTitle: jobTitle,
          company: company,
          image: this.postImageSrc,
          testMode: testMode,
        };

        this.progressValue.set(30);

        // Get metadata from LinkedIn for the user
        const metadataResult = await fetch(getLinkedInMetadataUrl, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(getLinkedInMetadataBody),
        });

        const metadataResults = await metadataResult.json();
        
        this.progressValue.set(50);

        // prep the image
        if (campaign.usePersonalisation) {
          // Get the user's LinkedIn profile pic
          const userProfileImageResponse = await fetch(metadataResults.userInfo.picture);
          const userProfileImageBlob = await userProfileImageResponse.blob();
          const userProfileImageBase64 = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(userProfileImageBlob);
          });

          const roundProfilePic = await this.createRoundImage(userProfileImageBase64, 130, 130);

          this.postImageSrc = await this.processPostImage(
            this.postImageSrc,
            roundProfilePic,
            campaign.personalisationColour,
            metadataResults.userInfo.given_name,
            metadataResults.userInfo.family_name,
            jobTitle,
            company
          );
        }
        
        this.progressValue.set(65);

        // Hit the AWS lambda to post to LinkedIn
        const createLinkedInPostBody = {
          authorization_code: authorization_code,
          state: state,
          accessToken: metadataResults.linkedInAccessToken,
          clientAccountId: clientAccountId,
          campaignId: campaignId,
          postText: campaign.postTitle,
          postBody: campaign.postBody,
          postForwardUrl: campaign.postForwardUrl,
          jobTitle: jobTitle,
          company: company,
          image: this.postImageSrc,
          userInfo: metadataResults.userInfo,
          testMode: testMode,
        };
        
        const interval = setInterval(() => {
          this.progressValue.update((value) => {
            if (value < 100) {
              return value + 5;
            } else {
              clearInterval(interval);
              return value;
            }
          });
        }, 500);

        // Post to linkedin
        const result = await fetch(createLinkedInPostUrl, {
          method: 'POST',
          body: JSON.stringify(createLinkedInPostBody),
          headers: headers,
        });

        const resultData = await result.json();
        const postId = resultData.PostId;

        // For testing image changes without posting to linkedin. Comment out the above lines to "const result =" and uncomment the line below
        // console.log(this.postImageSrc);
        // const postId = '1';

        if (!isNullOrUndefined(testMode) && testMode === 'true') {
          console.log('Full page success');
        }

        this.showSpinner.set(false);

        if (isNullOrUndefined(postId)) {
          const errorString =
            'An error occurred while creating the post. Please check LinkedIn to see whether a post was created.';
          this.router.navigate(['/share-complete'], {
            state: {
              isSuccess: false,
              errorMessage: errorString,
            },
          });
        } else {
          this.router.navigate(['/share-complete'], {
            state: {
              isSuccess: true,
              eventImageUrl: `${this.buzzwaveS3Url}/${campaign.logoUrl}`,
              eventName: campaign.eventName,
              useIncentive: campaign.useIncentive,
              incentiveText: campaign.incentiveText,
              incentiveTCs: campaign.incentiveTCs,
              incentiveFulfillmentUrl: campaign.incentiveFulfillmentUrl,
            },
          });
        }
      } catch (error) {
        console.error(error);
        this.router.navigate(['/share-complete'], {
          state: {
            isSuccess: false,
            errorMessage: 'An error occurred while creating the post. Please try again later.',
          },
        });
      }
    } else if (campaignId && forwardUrl) {
      const linkedInPostClickUrl = this.awsApiUrl2 + '/linkedin-share-click';
      const headers = { 'content-type': 'application/json' };
      const body2 = {
        campaignId: campaignId,
        forwardUrl: forwardUrl,
        clientAccountId: clientAccountId,
        campaignShareId: viewedCampaignShareId,
        testMode: testMode,
      };

      try {
        // Hit the AWS lambda
        const interval = setInterval(() => {
          this.progressValue.update((value) => {
            if (value < 100) {
              return value + 20;
            } else {
              clearInterval(interval);
              return value;
            }
          });
        }, 500);
        await fetch(linkedInPostClickUrl, {
          method: 'POST',
          body: JSON.stringify(body2),
          headers: headers,
        });

        window.location.href = forwardUrl + `?buzzwavecampaignshareid=${viewedCampaignShareId}&buzzwavecampaignid=${campaignId}`;
      } catch (error) {
        console.error(error);
        this.router.navigate(['/share-complete'], {
          state: {
            isSuccess: false,
            errorMessage: 'An error occurred while creating the post. Please try again later.',
          },
        });
        window.location.href = forwardUrl + `?buzzwavecampaignshareid=${viewedCampaignShareId}`;
      }
    } else {
      window.location.href = 'https://buzzwave.io';
    }
  }

  private async getBase64ImageFromUrl(imageUrl: string) {
    const res = await fetch(imageUrl, { mode: 'cors', cache: 'no-store', credentials: 'omit' });
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(reader);
      };
      reader.readAsDataURL(blob);
    });
  }

  public async processPostImage(
    image: string,
    profileImageBase64: string,
    colour: string,
    givenName: string,
    familyName: string,
    jobTitle: string,
    company: string
  ) {
    const compressedImage = await this.compressImage(
      image,
      colour!,
      this.socialPostImageWidth,
      this.socialPostImageHeight,
      givenName,
      familyName,
      jobTitle,
      company
    );

    let imageY = 32;

    switch (this.textLineCount) {
      case 1:
        imageY = 44;
        break;
      case 2:
        imageY = 36;
        break;
    }

    const mergedImage = await mergeImages(
      [compressedImage, { src: profileImageBase64, x: 390, y: imageY }],
      {
        width: this.socialPostImageWidth,
        height: this.socialPostImageHeight,
      }
    );
    return mergedImage;
  }

  public createRoundImage(image: string, width: number, height: number): Promise<string> {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext('2d');

        ctx!.beginPath();
        ctx!.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, true);
        ctx!.closePath();
        ctx!.clip();

        ctx!.drawImage(img, 0, 0, width, height);
        const data = ctx!.canvas.toDataURL();
        res(data);
      };
      img.onerror = (error) => rej(error);
    });
  }

  public compressImage(
    src: string,
    color: string,
    imageWidth: number,
    imageHeight: number,
    givenName: string,
    familyName: string,
    jobTitle: string,
    company: string
  ): Promise<string> {
    const maxWidth = 170;
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = imageWidth;
        elem.height = imageHeight;
        elem.style.borderRadius = '50%';
        const ctx = elem.getContext('2d');
        ctx!.font = '15px Roboto, sans-serif';
        ctx!.fillStyle = color;
        ctx!.textAlign = 'center';
        ctx!.drawImage(img, 0, 0, this.socialPostImageHeight, this.socialPostImageWidth);

        let fullName = `${givenName} ${familyName}`;
        let shortenedFamilyName = familyName;

        if (fullName.length >= this.maxTextWidth) {
          fullName = fullName.slice(0, this.maxTextWidth) + '...';
        } else {
          fullName = `${givenName} ${familyName}`;
        }

        if (familyName.length >= this.maxTextWidth) {
          shortenedFamilyName = familyName.slice(0, this.maxTextWidth) + '...';
        }

        if (!isNullOrWhiteSpace(jobTitle) && !isNullOrWhiteSpace(company)) {
          // All three metadata fields
          ctx!.fillText(fullName, 453, 191, maxWidth);
          ctx!.fillText(jobTitle, 453, 211, maxWidth);
          ctx!.fillText(company, 453, 231, maxWidth);
          this.textLineCount = 3;
        } else if (!isNullOrWhiteSpace(jobTitle) || !isNullOrWhiteSpace(company)) {
          // Two metadata fields
          if (fullName.length >= this.maxTextWidth) {
            ctx!.fillText(givenName, 453, 191, maxWidth);
            ctx!.fillText(shortenedFamilyName, 453, 211, maxWidth);
            if (!isNullOrWhiteSpace(jobTitle)) {
              ctx!.fillText(jobTitle, 453, 231, maxWidth);
            } else {
              ctx!.fillText(company, 453, 231, maxWidth);
            }
            this.textLineCount = 2;
          } else {
            ctx!.fillText(fullName, 453, 191, maxWidth);
            if (!isNullOrWhiteSpace(jobTitle)) {
              ctx!.fillText(jobTitle, 453, 218, maxWidth);
            } else {
              ctx!.fillText(company, 453, 218, maxWidth);
            }
            this.textLineCount = 2;
          }
        } else if (fullName.length >= this.maxTextWidth) {
          // Just the name, spit on two lines to use space
          ctx!.fillText(givenName, 453, 194, maxWidth);
          ctx!.fillText(shortenedFamilyName, 453, 218, maxWidth);
          this.textLineCount = 2;
        } else {
          // Just the name
          ctx!.fillText(fullName, 453, 206, maxWidth);
          this.textLineCount = 1;
        }

        const data = ctx!.canvas.toDataURL();
        res(data);
      };
      img.onerror = (error) => rej(error);
    });
  }
}
