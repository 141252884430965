export const isNullOrUndefined = <T>(val: T): boolean => {
  return val === null || val === undefined;
};

export const isNullOrEmpty = <T>(items: T[]): boolean => {
  return isNullOrUndefined(items) || items.length === 0;
};

export const isNullOrWhiteSpace = (value: string): boolean => {
  return isNullOrUndefined(value) || value.trim() === '';
};
