import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { isNullOrUndefined } from '../shared/helpers/helper-functions';

@Component({
  selector: 'app-share-click',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './share-click.component.html',
  styleUrl: './share-click.component.scss',
})
export class ShareClickComponent implements OnInit {
  public showSpinner = signal<boolean>(true);
  public showSuccess = signal<boolean>(false);
  public showFailure = signal<boolean>(false);
  public errorMessage = signal<string>('');
  public postId = signal<string>('');
  public linkedInNewPostUrl = 'https://www.linkedin.com/embed/feed/update/';
  private awsApiUrl2 = environment.buzzwaveApiUrl; // 'https://q1pm5pcwh0.execute-api.eu-north-1.amazonaws.com'; // jd-sb
  private buzzwaveS3Url = environment.buzzwaveS3Url;
  // 'https://amplify-buzzwaveadmin-jon-buzzwaveclientassetsbuck-no2dmd9ipiy7.s3.eu-north-1.amazonaws.com'; // jd-sb

  async ngOnInit(): Promise<void> {

    const url = new URL(window.location.href);
    const authorization_code = url.searchParams.get('code');
    const state = url.searchParams.get('state');
    const campaignId = url.searchParams.get('buzzwavecampaignid');
    const forwardUrl = url.searchParams.get('forwardurl');
    const clientAccountId = url.searchParams.get('buzzwaveclientaccountid');
    const viewedCampaignShareId = url.searchParams.get('buzzwavecampaignshareid');

    if (authorization_code && state) {
      this.showSpinner.set(true);
      const createLinkedInPostUrl = this.awsApiUrl2 + '/campaign-linkedin-share';
      const headers = { 'content-type': 'application/json' };

      // Get supporting advert metadata for use in the post
      const campaignMetadataStorageValue = sessionStorage.getItem('buzzwave-campaign-metadata');
      let campaignMetadata = campaignMetadataStorageValue
        ? JSON.parse(campaignMetadataStorageValue)
        : null;

      if (!campaignMetadata) {
        // Get the client's advert metadata from AWS
        const url =
          this.awsApiUrl2 + `/campaigns-by-clientaccountid?clientAccountId=${clientAccountId}`;
        const headers = { 'content-type': 'application/json' };

        try {
          const result = await fetch(url, {
            method: 'GET',
            headers: headers,
          });

          const results = await result.json();

          const campaignArray = Array.isArray(results) ? results : [];

          if (campaignArray.length > 0) {
            campaignMetadata = campaignArray;
            sessionStorage.setItem('buzzwave-campaign-metadata', JSON.stringify(campaignMetadata));
          }
        } catch (error) {
          console.error(error);
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const campaign = campaignMetadata.find((c: any) => c.campaignId === campaignId);

      const body = {
        authorization_code: authorization_code,
        state: state,
        clientAccountId: clientAccountId,
        postImageUrl: `${this.buzzwaveS3Url}/${campaign.postImageUrl}`,
        postText: campaign.postTitle,
        postForwardUrl: campaign.postForwardUrl,
      };

      try {
        // Hit the AWS lambda to post to LinkedIn
        const result = await fetch(createLinkedInPostUrl, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: headers,
        });

        console.info('Success');
        const resultData = await result.json();
        const postId = resultData.PostId;

        this.showSpinner.set(false);

        if (isNullOrUndefined(postId)) {
          const errorString =
            'An error occurred while creating the post. Please check LinkedIn to see whether a post was created.';
          this.errorMessage.set(errorString);
          this.showFailure.set(true);
          console.error(errorString);
        } else {
          this.postId.set(postId);
          this.showSuccess.set(true);
        }
      } catch (error) {
        this.errorMessage.set('An error occurred while creating the post. Please try again later.');
        this.showFailure.set(true);
        console.error(error);
      }
    } else if (campaignId && forwardUrl) {
      this.showSpinner.set(true);
      const linkedInPostClickUrl = this.awsApiUrl2 + '/linkedin-share-click';
      const headers = { 'content-type': 'application/json' };
      const body2 = {
        campaignId: campaignId,
        forwardUrl: forwardUrl,
        clientAccountId: clientAccountId,
        campaignShareId: viewedCampaignShareId,
      };

      try {
        // Hit the AWS lambda
        await fetch(linkedInPostClickUrl, {
          method: 'POST',
          body: JSON.stringify(body2),
          headers: headers,
        });

        window.location.href = forwardUrl + '?buzzwavecampaignshareid=' + viewedCampaignShareId;
      } catch (error) {
        this.errorMessage.set('An error occurred. Please try again later.');
        this.showFailure.set(true);
        console.error(error);
        window.location.href = forwardUrl + `?buzzwavecampaignshareid=${viewedCampaignShareId}`;
      }
    } else {
      window.location.href = 'https://buzzwave.io';
    }
  }
}
