import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import axios from 'axios';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { PageSpinnerService } from '../shared/components/page-spinner/services/page-spinner.service';
import { isNullOrWhiteSpace } from '../shared/helpers/helper-functions';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule, FormsModule],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent implements OnInit, OnDestroy {
  public isLoading = signal<boolean>(true);
  public eventName = signal<string>('');
  public postTitle = signal<string>('');
  public postForwardUrl = signal<string>('');
  public postBody = signal<string>('');
  public postImageUrl = signal<string>('');
  public eventImageUrl = signal<string>('');
  public title = signal<string>('');
  public text = signal<string>('');
  public incentiveText = signal<string>('');
  public useIncentive = signal<boolean>(false);
  public usePersonalisation = signal<boolean>(false);
  public incentiveTCs = signal<string>('');
  public buttonText = signal<string>('');
  public isTest = signal<boolean>(false);
  public campaignFound = signal<boolean>(true);
  public jobTitle = '';
  public company = '';

  private buzzwaveApiUrl = '';
  private buzzwaveS3Url = '';
  private campaignId = '';
  private clientAccountId = '';
  private readonly onDestroy$ = new Subject();

  constructor(private spinner: PageSpinnerService) {
    this.buzzwaveApiUrl = environment.buzzwaveApiUrl;
    this.buzzwaveS3Url = environment.buzzwaveClientAssetsS3Url;
  }

  async ngOnInit(): Promise<void> {
    this.spinner.showLoader();
    // Get the campaignId and test validity
    const url = window.location.href;
    const firstSlashIndex = url.indexOf('/', url.indexOf('https://') + 8);
    this.campaignId = url.substring(firstSlashIndex + 1, firstSlashIndex + 1 + 36);

    const guidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (!guidRegex.test(this.campaignId)) {
      window.location.href = 'https://buzzwave.io';
    }

    this.isTest.set(url.endsWith('/test'));

    // Get the data
    try {
      const response = await axios.get(
        `${this.buzzwaveApiUrl}/campaign?campaignId=${this.campaignId}&isTest=${this.isTest()}`
      );

      if (response.status !== 200) {
        this.campaignFound.set(false);
        this.isLoading.set(false);
        this.spinner.hideLoader();
      } else {
        if (response.data?.redirect === true) {
          window.location.href = response.data?.redirectUrl ?? 'https://buzzwave.io';
        } else {
          this.postTitle.set(response.data.postTitle);
          this.postForwardUrl.set(response.data.postForwardUrl);
          this.postBody.set(response.data.postBody);
          if (response.data.usePersonalisation) {
            if (isNullOrWhiteSpace(response.data.postPersonalisationImagePreviewUrl)) {
              this.postImageUrl.set('/assets/defaultImage.png');
            } else {
              this.postImageUrl.set(
                `${this.buzzwaveS3Url}/${response.data.postPersonalisationImagePreviewUrl}`
              );
            }
          } else {
            if (isNullOrWhiteSpace(response.data.postImageUrl)) {
              this.postImageUrl.set('/assets/defaultImage.png');
            } else {
              this.postImageUrl.set(`${this.buzzwaveS3Url}/${response.data.postImageUrl}`);
            }
          }
          if (isNullOrWhiteSpace(response.data.logoUrl)) {
            this.eventImageUrl.set('/assets/defaultImage.png');
          } else {
            this.eventImageUrl.set(`${this.buzzwaveS3Url}/${response.data.logoUrl}`);
          }
          this.clientAccountId = response.data.clientAccountId;
          this.eventName.set(response.data.eventName);
          this.title.set(response.data.title);
          this.text.set(response.data.text);
          this.incentiveTCs.set(response.data.incentiveTCs);
          this.incentiveText.set(response.data.incentiveText);
          this.buttonText.set(response.data.buttonText);
          this.useIncentive.set(response.data.useIncentive ?? false);
          this.usePersonalisation.set(response.data.usePersonalisation ?? false);
          this.isLoading.set(false);
          this.spinner.hideLoader();
        }
      }
    } catch {
      this.campaignFound.set(false);
      this.isLoading.set(false);
      this.spinner.hideLoader();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  // 1 Opens a modal that starts the LinkedIn auth to post workflow
  async shareNowClicked() {
    if (this.campaignFound()) {
      this.spinner.showLoader();

      const errorMessage =
        'An error occurred while getting the LinkedIn authorization URL. Please try again later.';

      // Make a request to the CORS proxy to get the LinkedIn login HTML
      const url =
        this.buzzwaveApiUrl +
        `/linkedin-auth-url?campaignId=${this.campaignId}&clientAccountId=${this.clientAccountId}&jobTitle=${this.jobTitle}&company=${this.company}&testMode=false`;
      const headers = { 'content-type': 'application/json' };

      try {
        const result = await fetch(url, {
          method: 'GET',
          headers: headers,
        });
        const response = await result.json();
        // Redirect the user to the LinkedIn authorization URL
        window.location.href = response.linkedInAuthUrl;
      } catch (error) {
        console.error(error);
        this.spinner.hideLoader();
        window.document.body.innerHTML = this.getErrorHtmlString(errorMessage);
      }
    }
  }

  getErrorHtmlString(errorMessage: string): string {
    return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <title>Error</title>
    <style>
      body {
          font-family: Arial, sans-serif;
          background-color: #f2f2f2;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          margin: 0;
      }
  
      .content-container {
          text-align: center;
          background-color: #fff;
          margin: 10px 20px;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      }
  
      h1 {
        color: #ff6347;
        font-size: 48px;
        margin-bottom: 20px;
      }
  
      p {
        color: #666;
        font-size: 14px;
        margin-bottom: 30px;
      }
  
      a {
        color: #007bff;
        text-decoration: none;
      }
  
      a:hover {
        text-decoration: underline;
      }
    </style>
  </head>
  <body>
    <div class="content-container">
      <h1>Oops! Something went wrong.</h1>
      <p>${errorMessage}</p>
    </div>
  </body>
  </html>
  `;
  }
}
