import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { isNullOrUndefined } from '../shared/helpers/helper-functions';

@Component({
  selector: 'app-share-click',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './share-click.component.html',
  styleUrl: './share-click.component.scss',
})
export class ShareClickComponent implements OnInit {
  public showSpinner = signal<boolean>(true);
  public buzzwaveAdminAssetsS3Url = signal<string>('');
  private awsApiUrl2 = environment.buzzwaveApiUrl;
  private buzzwaveS3Url = environment.buzzwaveClientAssetsS3Url;

  constructor(private router: Router) {
    this.buzzwaveAdminAssetsS3Url.set(environment.buzzwaveAdminAssetsS3Url);
  }

  async ngOnInit(): Promise<void> {

    const url = new URL(window.location.href);
    const authorization_code = url.searchParams.get('code');
    const state = url.searchParams.get('state');
    const campaignId = url.searchParams.get('buzzwavecampaignid');
    const forwardUrl = url.searchParams.get('forwardurl');
    const clientAccountId = url.searchParams.get('buzzwaveclientaccountid');
    const viewedCampaignShareId = url.searchParams.get('buzzwavecampaignshareid');
    const jobTitle = url.searchParams.get('buzzwavejobtitle') ?? '';
    const company = url.searchParams.get('buzzwavecompany') ?? '';
    const testMode = url.searchParams.get('testMode');

    if (authorization_code && state) {
      this.showSpinner.set(true);
      const createLinkedInPostUrl = this.awsApiUrl2 + '/campaign-linkedin-share';
      const headers = { 'content-type': 'application/json' };

      // Get supporting advert metadata for use in the post
      const campaignMetadataStorageValue = sessionStorage.getItem('buzzwave-campaign-metadata');
      let campaignMetadata = campaignMetadataStorageValue
        ? JSON.parse(campaignMetadataStorageValue)
        : null;

      if (!campaignMetadata) {
        // Get the client's advert metadata from AWS
        const url =
          this.awsApiUrl2 + `/campaigns-by-clientaccountid?clientAccountId=${clientAccountId}`;
        const headers = { 'content-type': 'application/json' };

        try {
          const result = await fetch(url, {
            method: 'GET',
            headers: headers,
          });

          const results = await result.json();

          const campaignArray = Array.isArray(results) ? results : [];

          if (campaignArray.length > 0) {
            campaignMetadata = campaignArray;
            sessionStorage.setItem('buzzwave-campaign-metadata', JSON.stringify(campaignMetadata));
          }
        } catch (error) {
          console.error(error);
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const campaign = campaignMetadata.find((c: any) => c.campaignId === campaignId);

      const body = {
        authorization_code: authorization_code,
        state: state,
        clientAccountId: clientAccountId,
        postImageUrl: `${this.buzzwaveS3Url}/${campaign.postImageUrl}`,
        postText: campaign.postTitle,
        postBody: campaign.postBody,
        postForwardUrl: campaign.postForwardUrl,
        jobTitle: jobTitle,
        company: company,
        testMode: testMode
      };

      try {
        // Hit the AWS lambda to post to LinkedIn
        const result = await fetch(createLinkedInPostUrl, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: headers,
        });

        const resultData = await result.json();
        console.log(resultData);
        const postId = resultData.PostId;
        

        if (!isNullOrUndefined(testMode) && testMode === 'true') {
          console.log('Full page success');
        }

        this.showSpinner.set(false);

        if (isNullOrUndefined(postId)) {
          const errorString =
            'An error occurred while creating the post. Please check LinkedIn to see whether a post was created.';
          this.router.navigate(['/share-complete'], { state: {
            isSuccess: false,
            errorMessage: errorString
          }});
        } else {
          this.router.navigate(['/share-complete'], { state: {
            isSuccess: true,
            eventImageUrl: `${this.buzzwaveS3Url}/${campaign.logoUrl}`,
            eventName: campaign.eventName,
            useIncentive: campaign.useIncentive,
            incentiveText: campaign.incentiveText,
            incentiveTCs: campaign.incentiveTCs,
            incentiveFulfillmentUrl: campaign.incentiveFulfillmentUrl,
          }});
        }
      } catch (error) {
        console.error(error);
        this.router.navigate(['/share-complete'], { state: {
          isSuccess: false,
          errorMessage: 'An error occurred while creating the post. Please try again later.'
        }});
      }
    } else if (campaignId && forwardUrl) {
      this.showSpinner.set(true);
      const linkedInPostClickUrl = this.awsApiUrl2 + '/linkedin-share-click';
      const headers = { 'content-type': 'application/json' };
      const body2 = {
        campaignId: campaignId,
        forwardUrl: forwardUrl,
        clientAccountId: clientAccountId,
        campaignShareId: viewedCampaignShareId,
        testMode: testMode
      };

      try {
        // Hit the AWS lambda
        await fetch(linkedInPostClickUrl, {
          method: 'POST',
          body: JSON.stringify(body2),
          headers: headers,
        });

        window.location.href = forwardUrl + '?buzzwavecampaignshareid=' + viewedCampaignShareId;
      } catch (error) {
        console.error(error);
        this.router.navigate(['/share-complete'], { state: {
          isSuccess: false,
          errorMessage: 'An error occurred while creating the post. Please try again later.'
        }});
        window.location.href = forwardUrl + `?buzzwavecampaignshareid=${viewedCampaignShareId}`;
      }
    } else {
      window.location.href = 'https://buzzwave.io';
    }
  }
}
