<div class="popup-container">
  <div *ngIf="showSpinner()" class="spinner-container">
    <div class="spinner"></div>
    <img
      src="https://buzzwave-admin-assets.s3.eu-north-1.amazonaws.com/buzzwave-logo-middle.png"
      alt="Overlay Image"
      class="overlay-image"
    />
  </div>
  <div *ngIf="showSuccess()" class="content-container">
    <h1>Thanks!</h1>
    <p>Your post has been successfully created on LinkedIn.</p>
    <a href="{{ linkedInNewPostUrl }}{{ postId() }}/">View your post</a>
  </div>
  <div *ngIf="showFailure()" class="content-container">
    <h1>Oops! Something went wrong.</h1>
    <p>{{ errorMessage() }}</p>
  </div>
</div>
