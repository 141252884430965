import axios from 'axios';
import { Subject } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Meta, Title } from '@angular/platform-browser';

import { environment } from '../../environments/environment';
import { PageSpinnerService } from '../shared/components/page-spinner/services/page-spinner.service';
import { isNullOrWhiteSpace } from '../shared/helpers/helper-functions';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule, FormsModule],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent implements OnInit, OnDestroy {
  public isLoading = signal<boolean>(true);
  public eventName = signal<string>('');
  public postTitle = signal<string>('');
  public postForwardUrl = signal<string>('');
  public postForwardUrlText = signal<string>('');
  public postBody = signal<string>('');
  public shortPostBody = signal<string>('');
  public postImageUrl = signal<string>('');
  public eventImageUrl = signal<string>('');
  public title = signal<string>('');
  public text = signal<string>('');
  public incentiveText = signal<string>('');
  public useIncentive = signal<boolean>(false);
  public usePersonalisation = signal<boolean>(false);
  public showBuzzwaveBranding = signal<boolean>(false);
  public incentiveTCs = signal<string>('');
  public buttonText = signal<string>('');
  public isTest = signal<boolean>(false);
  public campaignFound = signal<boolean>(true);
  public showFullPostBody = signal<boolean>(true);
  public jobTitle = '';
  public company = '';

  private buzzwaveApiUrl = '';
  private buzzwaveS3Url = '';
  private campaignId = '';
  private campaignParticipantId = '';
  private clientAccountId = '';
  private readonly onDestroy$ = new Subject();

  constructor(
    private spinner: PageSpinnerService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.buzzwaveApiUrl = environment.buzzwaveApiUrl;
    this.buzzwaveS3Url = environment.buzzwaveClientAssetsS3Url;

    window.onbeforeunload = function () {
      sessionStorage.setItem('buzzwave-origin', window.location.href);
    };

    window.onload = async function onLoad() {
      if (window.location.href == sessionStorage.getItem('buzzwave-origin')) {
        sessionStorage.removeItem('buzzwave-campaign-metadata');
      }
    };
  }

  async ngOnInit(): Promise<void> {
    this.spinner.showLoader();
    // Get the campaignId and test validity
    const url = window.location.href;
    const firstSlashIndex = url.indexOf('/', url.indexOf('https://') + 8);
    this.campaignId = url.substring(firstSlashIndex + 1, firstSlashIndex + 1 + 36);

    const guidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (!guidRegex.test(this.campaignId)) {
      window.location.href = 'https://buzzwave.io';
    }

    this.campaignParticipantId = url.substring(firstSlashIndex + 38, firstSlashIndex + 38 + 36);

    this.isTest.set(url.endsWith('/test'));

    if (this.isTest()) {
      const newUrl = url.replace(/\/test$/, '');
      window.history.replaceState({}, document.title, newUrl);
    }

    // Get the data
    try {
      let url = `${this.buzzwaveApiUrl}/campaign?campaignId=${
        this.campaignId
      }&isTest=${this.isTest()}`;
      if (guidRegex.test(this.campaignParticipantId)) {
        url += `&campaignParticipantId=${this.campaignParticipantId}`;
      }

      const response = await axios.get(url);

      if (response.status !== 200) {
        this.campaignFound.set(false);
        this.isLoading.set(false);
        this.spinner.hideLoader();
      } else {
        if (response.data?.redirect === true) {
          window.location.href = response.data?.redirectUrl ?? 'https://buzzwave.io';
        } else {
          const isTailorMade: boolean = response.data.isTailorMade;

          if (isTailorMade && !guidRegex.test(this.campaignParticipantId)) {
            window.location.href = response.data?.postForwardUrl ?? 'https://buzzwave.io';
          }

          this.postTitle.set(
            isTailorMade && response.data.overridePostTitle === true
              ? response.data.overridePostTitleValue
              : response.data.postTitle
          );
          this.postForwardUrl.set(response.data.postForwardUrl);
          this.postForwardUrlText.set(this.getLinkedInUrl());

          // Replace any body URLs with redirect URL
          let postBody =
            isTailorMade && response.data.overridePostTitle === true
              ? response.data.overridePostBodyValue
              : response.data.postBody;

          postBody = this.replaceUrls(postBody);

          this.postBody.set(postBody);
          if (response.data.isTailorMade) {
            if (isNullOrWhiteSpace(response.data.participantPostImageUrl)) {
              this.postImageUrl.set('/assets/defaultImage.png');
            } else {
              this.postImageUrl.set(
                `${this.buzzwaveS3Url}/${response.data.participantPostImageUrl}`
              );
            }
          } else if (response.data.usePersonalisation) {
            if (isNullOrWhiteSpace(response.data.postPersonalisationImagePreviewUrl)) {
              this.postImageUrl.set('/assets/defaultImage.png');
            } else {
              this.postImageUrl.set(
                `${this.buzzwaveS3Url}/${response.data.postPersonalisationImagePreviewUrl}`
              );
            }
          } else {
            if (isNullOrWhiteSpace(response.data.postImageUrl)) {
              this.postImageUrl.set('/assets/defaultImage.png');
            } else {
              this.postImageUrl.set(`${this.buzzwaveS3Url}/${response.data.postImageUrl}`);
            }
          }
          if (isNullOrWhiteSpace(response.data.logoUrl)) {
            this.eventImageUrl.set('/assets/defaultImage.png');
          } else {
            this.eventImageUrl.set(`${this.buzzwaveS3Url}/${response.data.logoUrl}`);
          }
          this.clientAccountId = response.data.clientAccountId;
          this.eventName.set(response.data.eventName);
          this.title.set(response.data.title);
          this.text.set(response.data.text);
          this.incentiveTCs.set(response.data.incentiveTCs);
          this.incentiveText.set(response.data.incentiveText);
          this.buttonText.set(response.data.buttonText);
          this.useIncentive.set(response.data.useIncentive ?? false);
          this.usePersonalisation.set(response.data.usePersonalisation ?? false);
          this.showBuzzwaveBranding.set(!(response.data.hideBuzzwaveBranding ?? true));

          // Set title and meta tags
          this.titleService.setTitle(this.eventName());
          this.metaService.updateTag({ name: 'og:site_name', content: this.eventName() });
          // this.metaService.updateTag({ name: 'title', content: this.eventName() });
          this.metaService.updateTag({
            name: 'description',
            content: this.text().length > 150 ? `${this.text().substring(0, 150)}...` : this.text(),
          });
          this.metaService.updateTag({
            name: 'og:description',
            content: this.text().length > 150 ? `${this.text().substring(0, 150)}...` : this.text(),
          });

          this.metaService.updateTag({ name: 'og:type', content: 'website' });

          this.metaService.updateTag({ name: 'og:url', content: window.location.href });
          this.metaService.updateTag({ name: 'og:title', content: this.eventName() });
          this.metaService.updateTag({ name: 'twitter:title', content: this.eventName() });
          this.metaService.updateTag({ name: 'og:image', content: this.eventImageUrl() });
          this.metaService.updateTag({ name: 'og:image:alt', content: 'Event logo' });
          this.metaService.updateTag({ name: 'twitter:image', content: this.eventImageUrl() });
          this.metaService.updateTag({ name: 'twitter:image:alt', content: 'Event logo' });

          this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
          this.metaService.updateTag({ name: 'twitter:creator', content: this.eventName() });
          this.metaService.updateTag({
            name: 'twitter:description',
            content: this.text().length > 150 ? `${this.text().substring(0, 150)}...` : this.text(),
          });
          this.metaService.updateTag({ name: 'twitter:site', content: this.eventName() });
          this.metaService.updateTag({ name: 'twitter:url', content: window.location.href });

          // this.metaService.updateTag({ name: 'og:description', content: this.text().length > 150 ? `${this.text().substring(0, 150)}...` : this.text() });
          // this.metaService.updateTag({ name: 'og:image', content: this.eventImageUrl() });
          // this.metaService.updateTag({ name: 'og:image:type', content: 'image/png' });
          // this.metaService.updateTag({ name: 'og:image:alt', content: 'Event logo' });
          // this.metaService.updateTag({ name: 'og:url', content: window.location.href });
          // this.metaService.updateTag({ name: 'og:site_name', content: this.eventName() });
          // this.metaService.updateTag({ name: 'og:type', content: 'website' });
          // this.metaService.updateTag({ name: 'og:updated_time', content: new Date().getTime().toString() });
          // this.metaService.updateTag({ name: 'og:image:width', content: '300' });
          // this.metaService.updateTag({ name: 'og:image:height', content: '300' });

          const responseDataJson = JSON.stringify(response.data);
          sessionStorage.setItem('buzzwave-campaign-metadata', `[${responseDataJson}]`);

          // Save the impression if not done already
          const impressionSaved = sessionStorage.getItem('buzzwave-impression-saved');

          if (!impressionSaved) {
            if (this.isTest()) {
              sessionStorage.setItem('buzzwave-impression-saved', 'true');
            } else {
              try {
                await axios.post(`${this.buzzwaveApiUrl}/impression-track`, {
                  campaignId: this.campaignId,
                  clientAccountId: this.clientAccountId,
                  impressionType: 'landingpage',
                });
                sessionStorage.setItem('buzzwave-impression-saved', 'true');
              } catch {
                // Do nothing
              }
            }
          }

          this.isLoading.set(false);
          this.spinner.hideLoader();
        }
      }
    } catch {
      this.campaignFound.set(false);
      this.isLoading.set(false);
      this.spinner.hideLoader();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  // 1 Opens a modal that starts the LinkedIn auth to post workflow
  async shareNowClicked() {
    if (this.campaignFound()) {
      this.spinner.showLoader();

      const errorMessage =
        'An error occurred while getting the LinkedIn authorization URL. Please try again later.';

      // Make a request to the CORS proxy to get the LinkedIn login HTML
      const url =
        this.buzzwaveApiUrl +
        `/linkedin-auth-url?campaignId=${this.campaignId}&clientAccountId=${this.clientAccountId}&jobTitle=${this.jobTitle}&company=${this.company}&testMode=false`;
      const headers = { 'content-type': 'application/json' };

      try {
        const result = await fetch(url, {
          method: 'GET',
          headers: headers,
        });
        const response = await result.json();
        // Redirect the user to the LinkedIn authorization URL
        window.location.href = response.linkedInAuthUrl;
      } catch (error) {
        console.error(error);
        this.spinner.hideLoader();
        window.document.body.innerHTML = this.getErrorHtmlString(errorMessage);
      }
    }
  }

  replaceUrls(text: string): string {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlPattern, (url) => {
      const newUrl = this.getLinkedInUrl();
      return `<a class="link" target="blank" href="${url}">${newUrl}</a>`;
    });
  }

  getLinkedInUrl(): string {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return `https://lnkd.in/${result}`;
  }

  showFullPostBodyClick() {
    this.showFullPostBody.set(true);
  }

  getErrorHtmlString(errorMessage: string): string {
    return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <title>Error</title>
    <style>
      body {
          font-family: Arial, sans-serif;
          background-color: #f2f2f2;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          margin: 0;
      }
  
      .content-container {
          text-align: center;
          background-color: #fff;
          margin: 10px 20px;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      }
  
      h1 {
        color: #ff6347;
        font-size: 48px;
        margin-bottom: 20px;
      }
  
      p {
        color: #666;
        font-size: 14px;
        margin-bottom: 30px;
      }
  
      a {
        color: #007bff;
        text-decoration: none;
      }
  
      a:hover {
        text-decoration: underline;
      }
    </style>
  </head>
  <body>
    <div class="content-container">
      <h1>Oops! Something went wrong.</h1>
      <p>${errorMessage}</p>
    </div>
  </body>
  </html>
  `;
  }
}
