<div class="popup-container">
  <div *ngIf="showSpinner()" class="spinner-container">
    <div class="spinner"></div>
    <img
      src="{{buzzwaveAdminAssetsS3Url()}}/buzzwave-logo-middle.png"
      alt="Overlay Image"
      class="overlay-image"
    />
  </div>
</div>
