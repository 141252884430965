import { Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ShareClickComponent } from './share-click/share-click.component';
import { ShareCompleteComponent } from './share-complete/share-complete.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingPageComponent
  },
  {
    path: 'share-click',
    pathMatch: 'full',
    component: ShareClickComponent
  },
  {
    path: 'share-complete',
    pathMatch: 'full',
    component: ShareCompleteComponent
  },
  {
    path: '**',
    component: LandingPageComponent,
    pathMatch: 'full'
  }
];
