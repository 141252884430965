import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageSpinnerComponent } from './shared/components/page-spinner/page-spinner.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    PageSpinnerComponent
  ],
  template: '<router-outlet></router-outlet><app-page-spinner></app-page-spinner>'
})
export class AppComponent {
  title = 'Buzzwave Go';
}
