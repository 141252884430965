import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import axios from 'axios';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { PageSpinnerService } from '../shared/components/page-spinner/services/page-spinner.service';
import { isNullOrWhiteSpace } from '../shared/helpers/helper-functions';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent implements OnInit, OnDestroy {
  public isLoading = signal<boolean>(true);
  public eventName = signal<string>('');
  public postTitle = signal<string>('');
  public postForwardUrl = signal<string>('');
  public postBody = signal<string>('');
  public postImageUrl = signal<string>('');
  public eventImageUrl = signal<string>('');
  public text = signal<string>('');
  public incentiveText = signal<string>('');
  public useIncentive = signal<boolean>(false);
  public incentiveTCs = signal<string>('');
  public buttonText = signal<string>('');
  public isTest = signal<boolean>(false);
  public campaignFound = signal<boolean>(true);

  private buzzwaveApiUrl = '';
  private buzzwaveS3Url = '';
  private campaignId = '';
  private readonly onDestroy$ = new Subject();

  constructor(private spinner: PageSpinnerService) {
    console.log('AppComponent constructor');
    this.buzzwaveApiUrl = environment.buzzwaveApiUrl;
    this.buzzwaveS3Url = environment.buzzwaveS3Url;
  }

  async ngOnInit(): Promise<void> {
    this.spinner.showLoader();
    // Get the campaignId and test validity
    const url = window.location.href;
    const firstSlashIndex = url.indexOf('/', url.indexOf('https://') + 8);
    this.campaignId = url.substring(firstSlashIndex + 1, firstSlashIndex + 1 + 36);

    const guidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (!guidRegex.test(this.campaignId)) {
      window.location.href = 'https://buzzwave.io';
    }

    this.isTest.set(url.endsWith('/test'));

    // Get the data
    try {
      const response = await axios.get(
        `${this.buzzwaveApiUrl}/campaign?campaignId=${this.campaignId}&isTest=${this.isTest()}`
      );

      if (response.status !== 200) {
        this.campaignFound.set(false);
        this.isLoading.set(false);
        this.spinner.hideLoader();
      } else {
        if (response.data?.redirect === true) {
          window.location.href = response.data?.redirectUrl ?? 'https://buzzwave.io';
        } else {
          this.postTitle.set(response.data.postTitle);
          this.postForwardUrl.set(response.data.postForwardUrl);
          this.postBody.set(response.data.postBody);
          if (isNullOrWhiteSpace(response.data.postImageUrl)) {
            this.postImageUrl.set('/assets/defaultImage.png');
          } else {
            this.postImageUrl.set(`${this.buzzwaveS3Url}/${response.data.postImageUrl}`);
          }
          if (isNullOrWhiteSpace(response.data.logoUrl)) {
            this.eventImageUrl.set('/assets/defaultImage.png');
          } else {
            this.eventImageUrl.set(`${this.buzzwaveS3Url}/${response.data.logoUrl}`);
          }
          this.eventName.set(response.data.eventName);
          this.text.set(response.data.text);
          this.incentiveTCs.set(response.data.incentiveTCs);
          this.incentiveText.set(response.data.incentiveText);
          this.buttonText.set(response.data.buttonText);
          this.useIncentive.set(response.data.useIncentive ?? false);
          this.isLoading.set(false);
          this.spinner.hideLoader();
        }
      }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      this.campaignFound.set(false);
      this.isLoading.set(false);
      this.spinner.hideLoader();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
