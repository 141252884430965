import { CommonModule } from '@angular/common';
import { Component, signal, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PageSpinnerService } from './services/page-spinner.service';

@Component({
  selector: 'app-page-spinner',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './page-spinner.component.html',
  styleUrl: './page-spinner.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom
})

export class PageSpinnerComponent {

  public loadingMessages = [
    'Whisking eggs...',
    'Chopping onions...',
    'Heating frying pot...',
    'Boiling water...',
    'Slicing tomatoes...',
    'Grating cheese...',
    'Washing salad...',
    'Mixing ingredients...',
    'Brewing coffee...',
    'Pouring tea...',
  ];
  public buzzwaveAdminAssetsS3Url = signal<string>('');

  constructor(public loader: PageSpinnerService) {
    this.buzzwaveAdminAssetsS3Url.set(environment.buzzwaveAdminAssetsS3Url);
  }
}
