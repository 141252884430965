<div class="popup-container">
  <div *ngIf="isLoading() === false" class="container">
    @if (campaignFound()) {
      <header class="header">
        <img src="{{ eventImageUrl() }}" alt="Event Logo" class="logo" />
        <h1>{{ eventName() }}</h1>
      </header>
      <div class="content">
        <section class="section-left">
          <div class="linkedin-preview-wrapper">
            <img class="image-preview-top" src="/assets/linkedInPostTop.png" alt="Image preview" />
            <p class="preview-text">{{ postTitle() }}&nbsp;<span class="link">https://lnkd.in/abc123</span></p>
            <p class="preview-text preline">
              {{ postBody() }}
            </p>
            <img id="image-preview" class="image-preview" [src]="postImageUrl()" alt="Image preview" />
            <img class="image-preview-bottom" src="/assets/linkedInPostBottom.png" alt="Image preview" />
          </div>
        </section>
        <section class="section-right">
          <div class="body-text">
            <h3><strong>Excited to attend? Let’s get your connections involved!</strong></h3>
            <p>{{ text() }}</p>
            <p *ngIf="useIncentive()">
              <em>{{ incentiveText() }}</em>
              <span *ngIf="incentiveTCs().length > 0">
                <br />
                <br />
                <a href="{{incentiveTCs()}}" target="_blank">Terms and conditions</a>
              </span>
            </p>
            <button class="centered-button">
              <img src="/assets/linkedin-in.png" alt="Button icon" class="button-icon" />
              <span class="button-text">{{ buttonText() }}</span>
            </button>
          </div>
        </section>
      </div>
    } @else {
      <header class="header">
        <h1>Oops something went wrong!</h1>
      </header>
      <div class="content">
        <div class="body-text">
          <p>
            We couldn't find the event you are looking for. Please check the URL and try again.
          </p>
        </div>
      </div>
    }
  </div>
</div>
